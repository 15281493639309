import React, {Component} from 'react';
import TemplateScreen from "../../TemplateScreen/TemplateScreen";
import WikiTable from './components/WikiTable';
import {Button, Form, Input, Modal, Space} from "antd";
import FormItemLabel from "antd/es/form/FormItemLabel";
import {createWikiMetaData, deleteWikiMetaData} from "../../services/wiki-service";
import {CONFIRM_DELETE_KEYWORD, MODEL_TYPE, NOTIFICATION_TYPE} from "../../constants";

class WikiManagement extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedWiki: null,
            notification: {},
            isCreateWikiModalVisible: false,
            isDeleteWikiModalVisible: false,
            isDeleteWikiButtonDisabled: true,
        }
    }

    notify(message, type) {
        this.setState({
            notification: {
                visible: true,
                message: message,
                type: type
            }
        });
    }

    handleWikiSelectionChange(selectedWiki) {
        this.setState({
            selectedWiki,
            isDeleteWikiButtonDisabled: false
        })
    }

    handleModalCancel() {
        this.setState({
            isCreateWikiModalVisible: false,
            isDeleteWikiModalVisible: false,
        })
    }

    handleWikiNameInput(event) {
        this.setState({
            newWikiID: event.target.value
        })
    }

    handleModalShow(modalType) {
        switch (modalType) {
            case MODEL_TYPE.CREATE_WIKI:
                this.setState({
                    isCreateWikiModalVisible: true
                });
                break;
            case MODEL_TYPE.DELETE_WIKI:
                this.setState({
                    isDeleteWikiModalVisible: true
                });
                break;
            case MODEL_TYPE.DELETE_SUB_WIKI:
                this.setState({
                    isDeleteWikiModalVisible: true
                });
                break;
            default:
                throw new Error("Invalid Modal Type");
        }
    }

    handleWikiCreate() {
        const {newWikiID} = this.state;
        createWikiMetaData(newWikiID).then(() => {
            this.notify("Successfully Created the Wiki", NOTIFICATION_TYPE.SUCCESS);
            window.location.reload();
        }).catch(error => {
            this.notify(JSON.stringify(error), NOTIFICATION_TYPE.ERROR);
        });
    }

    handleConfirmDeleteInput(event) {
        this.setState({
            confirmDeleteInput: event.target.value
        })
    }

    handleWikiDelete() {
        const {confirmDeleteInput, selectedWiki} = this.state;
        if (confirmDeleteInput !== CONFIRM_DELETE_KEYWORD) {
            return;
        }
        deleteWikiMetaData(selectedWiki["wiki_id"]).then(() => {
            this.notify("Successfully Deleted the Wiki", NOTIFICATION_TYPE.SUCCESS);
            window.location.reload();
        }).catch(error => {
            this.notify(JSON.stringify(error), NOTIFICATION_TYPE.SUCCESS);
        });
    }

    handleSubWikiCreate() {

    }

    render() {
        const { isCreateWikiModalVisible,
                isDeleteWikiModalVisible,
                isDeleteWikiButtonDisabled,
                notification
        } = this.state;
        return (
            <TemplateScreen content={
                <div>
                    <Space key={"WikiTableButtonsGroup"}
                           direction="horizontal" align="center" style={{marginBottom: 16}}>
                        <Button onClick={() => this.handleModalShow(MODEL_TYPE.CREATE_WIKI)}>Create</Button>
                        <Button disabled={isDeleteWikiButtonDisabled}
                                onClick={() => this.handleModalShow(MODEL_TYPE.DELETE_WIKI)}>Delete</Button>
                    </Space>
                    <WikiTable notify={(message, type)=>this.notify(message, type)}
                               onChange={(selectedWiki)=>{this.handleWikiSelectionChange(selectedWiki)}}
                               showModal={(modalType)=>{this.handleModalShow(modalType)}}
                    />
                    <Modal key={"WikiTableCreateWikiModal"}title="Create Wiki"
                           visible={isCreateWikiModalVisible}
                           onOk={() => this.handleWikiCreate()}
                           onCancel={() => this.handleModalCancel()}>
                        <Form>
                            <FormItemLabel label={"Wiki ID"}/>
                            <Input onChange={(event) => this.handleWikiNameInput(event)}/>
                        </Form>
                    </Modal>
                    <Modal key={"WikiTableDeleteWikiModal"}
                           title="Delete Wiki"
                           visible={isDeleteWikiModalVisible}
                           onOk={() => this.handleWikiDelete()}
                           onCancel={() => this.handleModalCancel()}>
                        <Form>
                            <FormItemLabel label={"Please type 'delete'"}/>
                            <Input onChange={(event) => this.handleConfirmDeleteInput(event)}/>
                        </Form>
                    </Modal>
                </div>
            }
                            notification={notification} />
        )
    }
}

export default WikiManagement;
