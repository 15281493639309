import React, {Component} from 'react';
import {connect} from 'react-redux';
import TemplateScreen from "../../TemplateScreen/TemplateScreen";
import MarkDownTextArea from './components/MarkDownTextArea';
import MarkDownRender from './components/MarkDownRender';
import {Spin, Row, Col, Divider, Button} from 'antd';
import UploadConfirmationModal from "./components/UploadConfirmationModal";
import {Cascader} from 'antd';
import {DOCUMENT, ENCODING, NOTIFICATION_TYPE, PATH_PARAMETER, WIKI_METADATA} from "../../constants";
import "./style/style.css";
import AssetsUploader from "./components/assets-uploader";
import {getAllWikiMetaData, getWikiMarkdown, getWikiMetaData} from "../../services/wiki-service";
import PropTypes from "prop-types";

/**
 * Component for markdown writing, dynamic rendering, submitting
 */
class MarkDownEditor extends Component {
    constructor(props) {
        super(props);
        this.state = {
            wikiName: "",
            isConfirmButtonDisabled: true,
            markdownPlain: "",
            path: "",
            isModalVisible: false,
            notification: {},
            document: [],
            options: [],
            loading: false
        }
    }

    componentDidMount() {
        const {dispatch} = this.props;
        const options = [];
        getAllWikiMetaData(dispatch).then((response)=> {
            response.data.forEach(item => {
                try {
                    const documents = JSON.parse(item[WIKI_METADATA.DOCUMENTS]);
                    const wikiNodes = [];
                    documents.forEach(document => {
                        const subNodes = this.deepCopyDocumentNode(document);
                        wikiNodes.push(subNodes);
                    });
                    wikiNodes.sort(this.wikiNodeCompare)
                    options.push({
                        value: item[PATH_PARAMETER.WIKI_ID],
                        label: item[PATH_PARAMETER.WIKI_ID],
                        children: wikiNodes
                    });
                    this.setState({options});
                } catch (error) {
                    console.log("Error in parsing options", error);
                }
            })
        });
    }

    /**
     * Helper function to get the wiki nodes tree
     * @param documentNode
     * @returns {{children: [], label: *, value: string}|{children: [], label: *, value: *}}
     */
    deepCopyDocumentNode(documentNode) {
        if (!Object.prototype.hasOwnProperty.call(documentNode,DOCUMENT.CHILDREN) ||
                documentNode.children.length === 0) {
            return {
                value: decodeURIComponent(documentNode["s3path"]),
                label: documentNode["text"],
                children: []
            };
        }

        let children = [];
        for (let i = 0; i < documentNode.children.length; i++) {
            let tmpNode = this.deepCopyDocumentNode(documentNode.children[i]);
            children.push(tmpNode);
        }
        children.sort(this.wikiNodeCompare)
        return {
            value: decodeURIComponent(documentNode[DOCUMENT.S3PATH]),
            label: documentNode["text"],
            children: children
        }
    }

    /**
     * Compare function for sorting the wiki nodes based on wiki label
     * @param node1
     * @param node2
     * @returns {number}
     */
    wikiNodeCompare(node1, node2) {
        if (node1["label"] > node2["label"]) {
            return 1;
        }
        if (node1["label"] < node2["label"]) {
            return -1;
        }
        return 0;
    }

    handleMarkDownTextChange(text) {
        this.setState({
            markdownPlain: text
        })
    }

    handleSubmit() {
        const {wikiName} = this.state;
        if (!wikiName || wikiName.length <= 0) {
            this.notify("Please Enter Wiki Name", NOTIFICATION_TYPE.ERROR);
            return;
        }
        getWikiMetaData(wikiName).then((response)=>{
            let document = JSON.parse(response.data["documents"]);
            this.setState({
                document: document,
                isModalVisible: true
            })
        }).catch(error => {
            this.notify(JSON.stringify(error), NOTIFICATION_TYPE.ERROR);
        })
    }

    handleModalCancel() {
        this.setState({
            isModalVisible: false
        })
    }

    notify(message, type) {
        this.setState({
            notification: {
                visible: true,
                message: message,
                type: type
            }
        })
    }

    onOptionsChange(value) {
        if (value.length <= 1) {
            this.notify("Please select the correct wiki path", NOTIFICATION_TYPE.ERROR);
            return;
        }
        this.setState({
            loading: true,
            wikiName: value[0]
        });

        // Parse the selected UI options to wiki path string
        const wikiName = value[0];
        let wikiPath = decodeURIComponent(value[value.length - 1]);
        this.setState({
            path: wikiPath
        })

        // Call Backend API to Fetch Wiki
        getWikiMarkdown(wikiName, wikiPath).then((data)=>{
            const buff = Buffer.from(data, ENCODING.BASE64);
            const markdownString = buff.toString(ENCODING.UTF8);
            this.setState({
                loading: false,
                markdownPlain: markdownString,
                isConfirmButtonDisabled: false
            });
        }).catch(error => {
            this.setState({
                loading: false,
                isConfirmButtonDisabled: false
            });
            this.notify("Error Loading Markdown Text: " + error.message, NOTIFICATION_TYPE.ERROR)
        });
    }

    displayRender(labels) {
        return labels[labels.length - 1];
    }

    render() {
        const {markdownPlain, isModalVisible, wikiName, path, notification,
            isConfirmButtonDisabled, document, options, loading} = this.state;
        return (
            <TemplateScreen content={
                <>
                    <Row className={"row-bottom-margin-20px"}>
                        <Col span={8}>
                            Please select the wiki: <br/>
                            <Cascader options={options}
                                      expandTrigger="hover"
                                      displayRender={this.displayRender}
                                      onChange={(event)=>{this.onOptionsChange(event)}}
                                      changeOnSelect
                                      />
                        </Col>
                        <Col span={8}>
                            <AssetsUploader wikiID={wikiName}
                                            subPath={path}
                                            notification={(message,type)=>this.notify(message,type)}
                            />
                        </Col>
                    </Row>
                    <Divider/>
                    {loading ? <div className={"spinner"}><Spin/></div> :
                        <Row className={"row-bottom-margin-20px"}>

                            <Col span={10}>
                                <a href={"https://commonmark.org/help/"}
                                   target="_blank" rel="noreferrer">Markdown Reference</a>
                                <MarkDownTextArea onChange={(text) => this.handleMarkDownTextChange(text)}
                                                  value={markdownPlain}/>
                            </Col>
                            <Col span={2} className={"page-divider-col"}>
                                <Divider type="vertical" className={"page-divider"}/>
                            </Col>
                            <Col span={10}>
                                <MarkDownRender value={markdownPlain}/>
                            </Col>
                        </Row>
                    }
                    <Row>
                        <Col span={10} offset={22}>
                            <Button type="primary" shape="round" size={"m"}
                                    disabled={isConfirmButtonDisabled}
                                    onClick={() => this.handleSubmit()}>
                                Confirm
                            </Button>
                        </Col>
                    </Row>
                    <UploadConfirmationModal visible={isModalVisible}
                                             notify={(message, type) => this.notify(message, type)}
                                             onCancel={() => this.handleModalCancel()}
                                             document={document}
                                             data={{wikiName: wikiName, path: path, content: markdownPlain}}
                    />
                </>
            } notification={notification}/>
        )
    }
}

MarkDownEditor.propTypes = {
    dispatch: PropTypes.func.isRequired,
    store: PropTypes.object.isRequired
};

const mapStateToProps = (store) => {
    return {
        store
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        dispatch: dispatch
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(MarkDownEditor);
