import { createSlice } from '@reduxjs/toolkit'
import {REDUX_STORE_NAME} from "../constants";

const wikiSlice = createSlice({
    name: REDUX_STORE_NAME.WIKI,
    initialState: {},
    reducers: {
        fetchWiki(state, action) {
            state.wiki = action.payload;
        }
    }
});
  
export const {fetchWiki} = wikiSlice.actions;
export default wikiSlice.reducer;
