import React, {Component} from 'react';
import {Input} from "antd";
import PropTypes from 'prop-types';

const { TextArea } = Input;

class MarkDownTextArea extends Component {
    constructor(props) {
        super(props);
        this.state = {
            textValue: ""
        }
    }

    handleInputChange(event) {
        const { onChange } = this.props;
        this.setState({
            textValue: event.target.value
        })
        onChange(event.target.value);
    }

    render() {
        const { value } = this.props;
        return (
            <div>
                <TextArea autoSize={{ minRows: 20, maxRows: 50 }}
                          placeholder="textarea with clear icon"
                          allowClear
                          value={value}
                          onChange={(event)=>this.handleInputChange(event)}/>
            </div>
        )
    }
}

MarkDownTextArea.propTypes = {
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string
}

export default MarkDownTextArea;
