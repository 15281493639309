import React, {Component} from "react";
import {
    Button, Col, Divider, Form, Input,
    List, Modal, Row, Select, Skeleton, Space, Spin, Tree
} from "antd";
import {CheckCircleOutlined} from '@ant-design/icons';
import PropTypes from "prop-types";
import {postWikiMarkdown} from "../../../services/wiki-service";
import {connect} from "react-redux";
import {PATH_PARAMETER} from "../../../constants";
import {
    convertSubWikiMetadataToTree,
    convertSubWikiTreeToFormData, convertSubWikiTreeToWikiMetaData,
    dfsTree,
    updateTreeNode
} from "../../../utils";
import "../style/style.css";

const {DirectoryTree} = Tree;

class UploadConfirmationModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            sideNavObject: [
                {
                    text: "Introduction",
                    type: "link",
                    href: "/Introduction"
                }
            ],
            wikiMetadata: [],
            newWikiName: "",
            subWikiTree: null,
            selectedWiki: "",
            formData: [
                {name: ["text"], value: ""},
                {name: ["type"], value: ""},
                {name: ["s3path"], value: ""},
                {name: ["href"], value: ""},
            ],
            formDataChildren: [],
            newChildren: {
                title: ""
            },
            notification: {}
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {data, store} = this.props;
        const wikiMetadata = store.wikiSlice.wiki.find((metadata) => {
            return metadata[PATH_PARAMETER.WIKI_ID] === data.wikiName
        });

        if (wikiMetadata === undefined) {
            return;
        }

        const documents = JSON.parse(wikiMetadata["documents"]);
        let tree = [];
        documents.forEach((node) => {
            tree.push(convertSubWikiMetadataToTree(node));
        });
        if (prevState.subWikiTree === null &&
            JSON.stringify(prevState.subWikiTree) !== JSON.stringify(tree)) {
            this.setState({
                subWikiTree: tree
            })
        }
    }

    async handleSubmit() {
        this.setState({
            loading: true
        })
        const {data, notify} = this.props;
        const {subWikiTree} = this.state;

        let wikiMetadata = [];
        for (let nodes of subWikiTree) {
            wikiMetadata.push(convertSubWikiTreeToWikiMetaData(nodes));
        }

        const blob = new Blob([data.content], {type: "text/plain"});
        const file = new File([blob], "index.md", {type: "text/plain"});

        postWikiMarkdown(data.wikiName, data.path, file, wikiMetadata).then(() => {
            this.handleNotification({
                message: "Successfully Uploaded the Wiki",
                type: "success",
                icon: <CheckCircleOutlined/>,
            });
            this.setState({
                loading: false
            })
        }).catch(error => {
            notify("Error in Updating Database Attributes: " + JSON.stringify(error), "error");
            this.setState({
                loading: false
            })
        });
    }

    handleNotification(notification) {
        this.setState({
            notification: notification
        })
    }

    onExpand() {
    }

    onSelect(event) {
        const selectedKey = event[0];
        const {subWikiTree} = this.state;
        const resultTree = dfsTree({
            key: null,
            title: null,
            isLeaf: false,
            children: subWikiTree
        }, selectedKey);

        const data = convertSubWikiTreeToFormData(resultTree);
        this.setState({
            selectedWiki: selectedKey,
            formData: data,
            formDataChildren: resultTree["children"]
        });
    }

    handleSave(event) {
        const {subWikiTree, selectedWiki, formDataChildren} = this.state;
        event["children"] = formDataChildren;
        let updatedWikiNode = {
            title: event["text"],
            key: event["s3path"],
            documentNode: event,
            children: formDataChildren,
            isLeaf: false
        };
        updatedWikiNode["children"] = formDataChildren;

        const updatedWikiTree = [];
        for (let nodes of subWikiTree) {
            const updatedNode = updateTreeNode(nodes, selectedWiki, updatedWikiNode);
            updatedWikiTree.push(updatedNode);
        }
        this.setState({
            subWikiTree: updatedWikiTree
        });
    }

    getFormDataValue(formData, key) {
        return formData.find((data) => {
            return data.name.includes(key)
        });
    }

    handleListItemRemove(wiki) {
        const {formDataChildren} = this.state;
        const newFormDataChildren = formDataChildren.filter((item)=>{
            return item["title"] !== wiki["title"]
        })
        this.setState({
            formDataChildren: newFormDataChildren
        })
    }

    handleListRenderer(item) {
        return (
            <List.Item actions={[
                <Button type="primary" key={"list-remove"} onClick={()=>{this.handleListItemRemove(item)}}>
                    Remove
                </Button>]}>
                    <Skeleton title={false} loading={false} active>
                        <div>{item.title}</div>
                    </Skeleton>
            </List.Item>);
    }

    addNewChildren() {
        const {formDataChildren, newChildren} = this.state;
        const res = formDataChildren.find((item)=>{
            return item["title"] === newChildren["title"]
        });
        // Avoid duplicate wiki
        if (res !== undefined) {
            return;
        }
        formDataChildren.push(newChildren);
        this.setState({
            formDataChildren
        })
    }

    handleNewChildrenChange(event) {
        const wikiName = event.target.value;
        this.setState({
            newChildren: {
                title: wikiName,
                key: wikiName,
                documentNode: {
                    text: wikiName,
                    type: "",
                    s3path: "",
                    href: "",
                    children: []
                },
                children: [],
                isLeaf: true
            }
        })
    }

    handleAddNewWikiInput(event) {
        this.setState({
            newWikiName: event.target.value
        });
    }

    handleAddNewWikiClick() {
        let {newWikiName, subWikiTree} = this.state;
        let newWikiNode = {
            title: newWikiName,
            key: newWikiName,
            documentNode: {
                text: newWikiName,
                type: "link",
                s3path: newWikiName,
                href: `/document/${newWikiName}`,
                children: []
            },
            children: [],
            isLeaf: true
        };
        subWikiTree.push(newWikiNode);
        this.setState({
            subWikiTree
        });
    }

    s3PathValidator(_, value) {
        const specialChars = new RegExp(/[~`!#$%\^&*+=\-\[\]\\';,{}|\\":<>\?]/);
        if (!specialChars.test(value) && value.indexOf(' ') < 0) {
            return Promise.resolve();
        }
        return Promise.reject(new Error('The s3 path contains special characters or spaces'));
    }

    render() {
        const {visible, onCancel} = this.props;
        const {loading, subWikiTree, formData, formDataChildren, newChildren, newWikiName} = this.state;
        return (
            <Modal title="Wiki Metadata Editor"
                   visible={visible}
                   width={"90%"}
                   confirmLoading={loading}
                   onOk={() => this.handleSubmit()}
                   onCancel={() => onCancel()}>
                 <Row>
                    <Col span={11}>
                        <Space direction={"vertical"} size="middle">
                            <DirectoryTree defaultExpandAll
                                           onSelect={(event) => this.onSelect(event)}
                                           onExpand={(event) => this.onExpand(event)}
                                           treeData={subWikiTree}/>
                            <Input onChange={(event)=>{
                                this.handleAddNewWikiInput(event)}}
                                   value={newWikiName}/>
                            <Button onClick={()=>{this.handleAddNewWikiClick()}}>Add New</Button>
                        </Space>

                    </Col>
                    <Col span={2} className={"page-divider-col"}>
                        <Divider type={"vertical"} className={"page-divider"}/>
                    </Col>
                    {loading ? <div><Spin/></div> :
                    <Col span={11}>
                        <Form name="global_state"
                              fields={formData}
                              onFinish={(event) => {
                                  this.handleSave(event)
                              }}>
                            <Form.Item
                                label="text"
                                name="text"
                                rules={[{required: true, message: 'Please input your text!'}]}
                            >
                                <Input/>
                            </Form.Item>
                            <Form.Item
                                label="type"
                                name="type"
                                rules={[{required: true, message: 'Please input your type!'}]}
                            >
                                <Select>
                                    <Select.Option value="link">Link</Select.Option>
                                </Select>
                            </Form.Item>
                            <Form.Item
                                label="s3path"
                                name="s3path"
                                rules={[{required: true, message: 'Please input your s3path!'},
                                    {validator: (_, value)=>this.s3PathValidator(_, value)}]}
                            >
                                <Input/>
                            </Form.Item>
                            <Form.Item
                                label="href"
                                name="href"
                                rules={[{required: true, message: 'Please input your href!'}]}
                            >
                                <Input/>
                            </Form.Item>
                            <Form.Item
                                label="children"
                                name="children"
                                rules={[{required: false, message: 'Please input your children!'}]}
                            >
                                <Input.Group compact>
                                    <Input
                                        style={{
                                            width: 'calc(100% - 200px)',
                                        }}
                                        value={newChildren.title}
                                        onChange={(event)=>{this.handleNewChildrenChange(event)}}
                                        defaultValue=""
                                    />
                                    <Button type="primary"
                                            onClick={()=>{this.addNewChildren()}}>
                                        Add
                                    </Button>
                                </Input.Group>
                                <List
                                    className="demo-loadmore-list"
                                    itemLayout="horizontal"
                                    dataSource={formDataChildren}
                                    renderItem={(item)=>this.handleListRenderer(item)}
                                />
                            </Form.Item>
                            <Form.Item wrapperCol={{offset: 2, span: 16}}>
                                <Button type="primary" htmlType="submit">
                                    Save
                                </Button>
                            </Form.Item>
                        </Form>
                    </Col>}
                </Row>
            </Modal>
        )
    }
}

UploadConfirmationModal.propTypes = {
    visible: PropTypes.bool.isRequired,
    onCancel: PropTypes.func.isRequired,
    data: PropTypes.object.isRequired,
    notify: PropTypes.func.isRequired,
    document: PropTypes.array.isRequired,
    dispatch: PropTypes.func.isRequired,
    store: PropTypes.object.isRequired
}

const mapStateToProps = (store) => {
    return {
        store
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        dispatch: dispatch
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(UploadConfirmationModal);
