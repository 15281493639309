import React, {Component} from 'react';
import PropTypes from "prop-types";
import TemplateScreen from "../../TemplateScreen/TemplateScreen";
import MarkDownRender from "../markdown-editor/components/MarkDownRender";
import {ENCODING, NOTIFICATION_TYPE, PATH_PARAMETER, WIKI_METADATA} from "../../constants";
import SideNavigation from "./components/SideNavigation";
import {Breadcrumb, Spin} from "antd";
import {getWikiMarkdown, getWikiMetaData} from "../../services/wiki-service";
import {convertWikiMetadataToNavigationTree} from "../../utils";
import { HomeOutlined } from '@ant-design/icons';
import "./style/style.css";

const GET_MARKDOWN_ERROR_MESSAGE = "Error Loading Wiki File: ";
const GET_WIKI_METADATA_ERROR_MESSAGE = "Error Loading Side Navigation: ";
const SPINNER_SIZE = "large";

class WikiRender extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            notification: {},
            markdownPlain: "",
            wiki_id: "",
            wikiMetaData: [],
            breadcrumb: null
        }
    }

    async componentDidMount() {
        const {match: {params: pathParam}} = this.props
        let wiki_id = pathParam[PATH_PARAMETER.WIKI_ID];
        let subPath = pathParam[0] || '';
        this.setState({
            wiki_id: wiki_id
        });

        getWikiMarkdown(wiki_id, subPath).then(data => {
            const buff = Buffer.from(data, ENCODING.BASE64);
            const markdownString = buff.toString(ENCODING.UTF8);
            this.setState({
                loading: false,
                markdownPlain: markdownString,
                breadcrumb: this.generateBreadCrumb(subPath)
            });
        }).catch(error => {
            this.setState({
                loading: false
            });
            this.notify(GET_MARKDOWN_ERROR_MESSAGE + JSON.stringify(error), NOTIFICATION_TYPE.ERROR)
        });

        getWikiMetaData(wiki_id).then(response => {
            let documents = response.data[WIKI_METADATA.DOCUMENTS]
            return JSON.parse(documents);
        }).then((wikiMetaData)=>{
            let navigationTree = [];
            wikiMetaData.forEach((node)=>{
                const navigationNode = convertWikiMetadataToNavigationTree(node);
                navigationTree.push(navigationNode);
            });
            this.setState({
                loading: false,
                wikiMetaData: navigationTree
            })
        }).catch(error => {
            this.setState({
                loading: false,
            });
            this.notify(GET_WIKI_METADATA_ERROR_MESSAGE + JSON.stringify(error), NOTIFICATION_TYPE.ERROR)
        });
    }

    generateBreadCrumb(wikiPath) {
        const breadCrumbPath = wikiPath.split('/');
        return (
            <div className={"breadcrumb"}>
                <Breadcrumb>
                    <Breadcrumb.Item key={"/"} href="/">
                        <HomeOutlined />
                    </Breadcrumb.Item>
                    {
                        breadCrumbPath.map((item)=>{
                            return (<Breadcrumb.Item key={item}>{item}</Breadcrumb.Item>)
                        })
                    }
                </Breadcrumb>
            </div>
            );
    }

    notify(message, type) {
        this.setState({
            notification: {
                message,
                type,
                visible: true
            }
        })
    }

    render() {
        const {breadcrumb, loading, notification, markdownPlain, wikiMetaData} = this.state;
        return (
            <TemplateScreen content={<Spin spinning={loading} size={SPINNER_SIZE}>
                                        <MarkDownRender value={markdownPlain} />
                                    </Spin>}
                            notification={notification}
                            breadcrumb={breadcrumb}
                            navigation={<SideNavigation items={wikiMetaData}/>} />
        )
    }
}

WikiRender.propTypes = {
    match: PropTypes.object.isRequired
}

export default WikiRender;
