import {createSlice} from "@reduxjs/toolkit";
import {REDUX_STORE_NAME} from "../constants";

const subWikiSlice = createSlice({
    name: REDUX_STORE_NAME.SUB_WIKI,
    initialState: {
        subWiki: {
            wiki_id: "",
            documents: [] //In json string format
        }
    },
    reducers: {
        updateSelectedWiki(state, action) {
            state.subWiki = action.payload;
        }
    }
});

export const {updateSelectedWiki} = subWikiSlice.actions;
export default subWikiSlice.reducer;
