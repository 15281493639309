import {DEFAULT_MARKDOWN_FILE_NAME, DOCUMENT, FILE_HANDLER_API, PATH_PARAMETER} from "../constants";

export function copyArray(array, startIndex, endIndex) {
    let arrayCopy = [];
    array.forEach((component, index) => {
        if (index < startIndex) {
            return;
        }
        if (index > endIndex) {
            return;
        }
        arrayCopy.push(component);
    });
    return arrayCopy;
}

export function getFileHandlerURL(wikiName, wikiPath) {
    return `${FILE_HANDLER_API}?${PATH_PARAMETER.WIKI_ID}=` +
        `${wikiName}&${PATH_PARAMETER.PATH}=${wikiPath}/${DEFAULT_MARKDOWN_FILE_NAME}`;
}

export function convertSubWikiMetadataToTree(documentNode) {
    if (!Object.prototype.hasOwnProperty.call(documentNode, DOCUMENT.CHILDREN) ||
        documentNode.children.length === 0) {
        return {
            title: documentNode[DOCUMENT.TEXT],
            key: decodeURIComponent(documentNode[DOCUMENT.S3PATH]),
            documentNode: documentNode,
            children: [],
            isLeaf: true
        }
    }

    let children = [];
    for (let i = 0; i < documentNode.children.length; i++) {
        let tmpNode = convertSubWikiMetadataToTree(documentNode.children[i]);
        children.push(tmpNode);
    }

    return {
        title: documentNode[DOCUMENT.TEXT],
        key: decodeURIComponent(documentNode[DOCUMENT.S3PATH]),
        documentNode: documentNode,
        children: children,
        isLeaf: false
    }
}

export function dfsTree(wikiTreeNode, key) {
    if (wikiTreeNode.key === key) {
        return wikiTreeNode;
    }

    if (wikiTreeNode.isLeaf === true ||
        !Object.prototype.hasOwnProperty.call(wikiTreeNode, DOCUMENT.CHILDREN) ||
        wikiTreeNode.children.length === 0) {
        return null;
    }

    for (let child of wikiTreeNode.children) {
        const res = dfsTree(child, key);
        if (res !== null) {
            return res;
        }
    }
    return null;
}

export function updateTreeNode(wikiTreeNode, key, updatedNode) {
    if (wikiTreeNode.key === key) {
        wikiTreeNode = updatedNode;
        return wikiTreeNode;
    }

    if (wikiTreeNode.isLeaf === true || wikiTreeNode.children.length === 0) {
        return wikiTreeNode;
    }
    for (let i = 0; i < wikiTreeNode.children.length; i++) {
        const updateTree = updateTreeNode(wikiTreeNode.children[i], key, updatedNode);
        wikiTreeNode.children[i] = updateTree;
    }
    return wikiTreeNode;
}

export function convertSubWikiTreeToWikiMetaData(subWikiTreeNode) {
    if (Object.prototype.hasOwnProperty.call(subWikiTreeNode, DOCUMENT.CHILDREN) &&
        subWikiTreeNode.children.length === 0) {
        return subWikiTreeNode.documentNode;
    }

    let parentNode = {
        text: subWikiTreeNode.documentNode[DOCUMENT.TEXT],
        type: subWikiTreeNode.documentNode[DOCUMENT.TYPE],
        s3path: subWikiTreeNode.documentNode[DOCUMENT.S3PATH],
        href: subWikiTreeNode.documentNode[DOCUMENT.HREF],
        children: []
    };
    for (let child of subWikiTreeNode.children) {
        const node = convertSubWikiTreeToWikiMetaData(child);
        parentNode.children.push(node);
    }
    return parentNode;
}

export function convertSubWikiTreeToFormData(subWikiTree) {
    const formData = [];
    const wiki = subWikiTree.documentNode;
    for (let key in wiki) {
        formData.push({
            name: [key],
            value: wiki[key]
        })
    }
    return formData;
}

export function isNullOrUndefined(object) {
    return ((object === undefined) || (object === null));
}

export function isEmpty(string) {
    if (isNullOrUndefined(string) || string.length === 0) {
        return true;
    }
    return false;
}

export function convertWikiMetadataToNavigationTree(wikiMetadata) {
    if (isNullOrUndefined(wikiMetadata.children) || wikiMetadata.children.length === 0) {
        return {
            key: wikiMetadata[DOCUMENT.TEXT],
            label: wikiMetadata[DOCUMENT.TEXT],
            type: wikiMetadata[DOCUMENT.TYPE],
            href: wikiMetadata[DOCUMENT.HREF]
        };
    }

    let navigationTreeChildren = [];
    wikiMetadata.children.forEach((node) => {
        const navigationNode = convertWikiMetadataToNavigationTree(node);
        navigationTreeChildren.push(navigationNode);
    });
    // Let the node has a child which equals parent node without children
    navigationTreeChildren.push({
        key: wikiMetadata[DOCUMENT.TEXT],
        label: wikiMetadata[DOCUMENT.TEXT],
        type: wikiMetadata[DOCUMENT.TYPE],
        href: wikiMetadata[DOCUMENT.HREF]
    });

    return {
        key: wikiMetadata[DOCUMENT.TEXT],
        label: wikiMetadata[DOCUMENT.TEXT],
        type: wikiMetadata[DOCUMENT.TYPE],
        href: wikiMetadata[DOCUMENT.HREF],
        children: navigationTreeChildren
    }
}

export function convertFormDataToSubWikiTreeNode(formData) {
    const node = {};
    for (let entry of formData) {
        node[entry["name"][0]] = entry["value"]
    }
    return node;
}
