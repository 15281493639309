import React, {Component} from "react";
import {Menu} from "antd";
import PropTypes from "prop-types";

const NAVIGATION_THEME = "dark";
const NAVIGATION_MODE = "inline";

class SideNavigation extends Component {

    constructor(props) {
        super(props);
        this.state = {
            wikiTree: []
        }
    }

    handleNavigationClick(event) {
        window.location.href = event.item.props.href;
    }

    render() {
        const {items} = this.props;
        return (
            <div>
                Headers
                <Menu mode={NAVIGATION_MODE}
                      theme={NAVIGATION_THEME}
                      items={items}
                      onSelect={(event)=>{this.handleNavigationClick(event)}}
                />
            </div>

        )
    }
}

SideNavigation.propTypes = {
    items: PropTypes.array.isRequired
}

export default SideNavigation
