import axios from 'axios';
import {DATABASE_API, DEFAULT_MARKDOWN_FILE_NAME, FILE_HANDLER_API, PATH_PARAMETER} from '../constants';
import { Auth } from '@aws-amplify/auth';
import { fetchWiki } from '../features/wiki-slice';

const databaseAPIInstance = axios.create({    
    baseURL: DATABASE_API,
})

const fileHandlerAPIInstance = axios.create({
    baseURL: FILE_HANDLER_API,
    proxy: {
        host: "d3uenvrm0t83gh.cloudfront.net",
        port: 443,
        protocol: 'https'
    }

});

databaseAPIInstance.interceptors.request.use(async (config) => {
    let token = await Auth.currentSession().then(data =>{
        return data.getIdToken().getJwtToken();
    });
    config.headers = { authorization: token};
    return config;
});

fileHandlerAPIInstance.interceptors.request.use(async (config) => {
    let token = await Auth.currentSession().then(data =>{
        return data.getIdToken().getJwtToken();
    });
    config.headers = { authorization: token};
    return config;
});

export const getAllWikiMetaData = async (dispatch) => {
    try {
        // api call
        const response = await databaseAPIInstance.get();
        let index = 0;
        let tableItem = [];
        response.data.forEach(item=>{
            tableItem.push({
                key: `${index}`,
                ...item
            })
            index++;
        });
        if (dispatch !== undefined) {
            dispatch(fetchWiki(tableItem));
        }
        return response;
    } catch(error) {
        throw("Error in getWiki service: ", error);
    }
}

export const getWikiMetaData = async (wikiID) => {
    try {
        // api call
        const response = await databaseAPIInstance.get(`/${wikiID}`);
        return response;
    } catch(error) {
        throw("Error in getWiki service: ", error);
    }
}

export const createWikiMetaData = async (wikiID) => {
    try {
        const url = `/${wikiID}`;
        await databaseAPIInstance.put(url, null);
    } catch(error) {
        throw("Error in createWiki service: ", error);
    }
}

export const updateWikiDocumentsMetadata = async (wikiID, documents) => {
    try {
        let payload = {
            "documents": JSON.stringify(documents)
        }
        await databaseAPIInstance.post(`/${wikiID}`, payload);
    } catch (error) {
        throw("Error in updating Wiki Documents Metadata: ", error);
    }
}

export const deleteWikiMetaData = async (wikiID) => {
    try {
        const url = `/${wikiID}`;
        await databaseAPIInstance.delete(url);
    } catch (error) {
        throw("Error in deleteWiki service: ", error);
    }
}

export const getWikiMarkdown = async (wikiID, subPath) => {
    subPath = decodeURIComponent(subPath);
    const url = `?${PATH_PARAMETER.WIKI_ID}=${wikiID}&${PATH_PARAMETER.PATH}=${subPath}/${DEFAULT_MARKDOWN_FILE_NAME}`
    const response = await fileHandlerAPIInstance.get(url);
    return response.data;
}

export const postWikiMarkdown = async (wikiID, subPath, file, document) => {
    subPath = decodeURIComponent(subPath);
    let url = `?${PATH_PARAMETER.WIKI_ID}=${wikiID}&${PATH_PARAMETER.PATH}=${subPath}/${DEFAULT_MARKDOWN_FILE_NAME}`;
    await fileHandlerAPIInstance.put(url, file, {
        headers: {
            "Content-Type": "text/html",
        }
    })
    return updateWikiDocumentsMetadata(wikiID, document);
}

export const deleteWikiMarkdown = async (wikiID, subPath) => {
    subPath = decodeURIComponent(subPath);
    const url = `?${PATH_PARAMETER.WIKI_ID}=${wikiID}&${PATH_PARAMETER.PATH}=${subPath}/${DEFAULT_MARKDOWN_FILE_NAME}`
    const response = await fileHandlerAPIInstance.delete(url);
    return response;
}
