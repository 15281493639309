import React from 'react';
import {Menu} from "antd";
import {EditOutlined, HomeOutlined, SearchOutlined, UploadOutlined} from "@ant-design/icons";
import {ROUTE_PATH_HOME, ROUTE_PATH_MARKDOWN_EDITOR} from './routes-constants';

export const sideNavigationItems = (
    <>
        <Menu.Item key="1" icon={<HomeOutlined />}>
            <a href={ROUTE_PATH_HOME}>Home</a>
        </Menu.Item>
        <Menu.Item key="2" icon={<SearchOutlined />}>
            Wiki Explorer
        </Menu.Item>
        <Menu.Item key="3" icon={<EditOutlined />}>
            <a href={ROUTE_PATH_MARKDOWN_EDITOR}>MarkDownEditor</a>
        </Menu.Item>
        <Menu.Item key="4" icon={<UploadOutlined />}>
            Assets Uploader
        </Menu.Item>
    </>);
export const DEFAULT_MARKDOWN_FILE_NAME = 'index.html';
export const FILE_HANDLER_API = "/api/FileHandlers"
export const PATH_PARAMETER = {
    WIKI_ID: "wiki_id",
    PATH: "path"
}
export const DOCUMENT = {
    TEXT: "text",
    TYPE: "type",
    HREF: "href",
    S3PATH: "s3path",
    CHILDREN: "children"
}
export const DATABASE_API = "/api/DataBaseHandlers";
export const NOTIFICATION_TYPE = {
    ERROR: "error",
    SUCCESS: "success"
}
export const ENCODING = {
    BASE64: "base64",
    UTF8: "utf-8"
};
export const CONFIRM_DELETE_KEYWORD = "delete";
export const WIKI_METADATA = {
    WIKI_ID: "wiki_id",
    ATTACHMENTS: "attachments",
    CREATED_ON: "created_on",
    MODIFIED_ON: "modified_on",
    DOCUMENTS: "documents",
    FOLDER: "folder"
}
export const REDUX_STORE_NAME = {
    WIKI: "wiki",
    SUB_WIKI: "subWiki"
}

export const MODEL_TYPE = {
    CREATE_SUB_WIKI: "createSubWiki",
    DELETE_SUB_WIKI: "deleteSubWiki",
    CREATE_WIKI: "createWiki",
    DELETE_WIKI: "deleteWiki"
}
