import React, {Component} from 'react';
import ReactMarkdown from 'react-markdown';
import PropTypes from 'prop-types';
import "../style/style.css";
import Code from 'react-code-prettify';
import rehypeRaw from 'rehype-raw';

const gfm = require('remark-gfm');

class MarkDownRender extends Component {

    imageRender(args) {
        return <img className={"small"} alt={args.alt} src={args.src} style={{width:"100%"}} />
    }

    videoRender({className, node}) {
        if (className === "language-video") {
            const url = node.children[0].value;
            return <iframe title={"Embedded Video"} width="420" height="315" src={url}/>
        }
        const match = /language-(\w+)/.exec(className || '');
        const language = match ? match[1] : "none";
        return (<Code codeString={node.children[0].value} language={language}></Code>);
    }

    render() {
        const {value} = this.props;
        return (
            <ReactMarkdown components={{
                img: this.imageRender,
                code: this.videoRender}}
                           remarkPlugins={[gfm]}
                           skipHtml={false}
                           rehypePlugins={[rehypeRaw]}>{value}</ReactMarkdown>
        )
    }
}

MarkDownRender.propTypes = {
    value: PropTypes.string.isRequired
}

MarkDownRender.defaultProps = {
    value: ""
}

export default MarkDownRender;
