import React, {Component} from "react";
import 'antd/dist/antd.css';
import './style/style.css';
import {Alert, Col, Image, Layout, Menu, Row} from 'antd';
import {
    EditOutlined,
    HomeOutlined,
    MenuFoldOutlined,
    MenuUnfoldOutlined,
    SearchOutlined,
    UploadOutlined
} from '@ant-design/icons';
import ProfileDropDown from './components/ProfileDropDown';
import PropTypes from "prop-types";
import {
    ROUTE_PATH_ASSETS_UPLOAD,
    ROUTE_PATH_HOME,
    ROUTE_PATH_MARKDOWN_EDITOR,
    ROUTE_PATH_WIKI_MANAGEMENT
} from "../constants/routes-constants";

const { Header, Sider, Content } = Layout;
const HEADER_IMAGE_PATH = "/duckyo.jpeg";

class TemplateScreen extends Component {
    constructor(props) {
        super(props);
        this.state = {
            collapsed: false,
        }
    }

    toggle() {
        const { collapsed } = this.state;
        this.setState({
            collapsed: !collapsed,
        });
    }

    redirectHome() {
        window.location.href = "/";
    }

    getNotification(notification) {
        if (notification.visible === true) {
            return (notification.visible &&
                <Alert message={notification.message} type={notification.type} showIcon
                       closeText={"Dismiss"} />)
        }
        return (<div></div>);
    }

    getSideNavigation() {
        const {navigation} = this.props;
        return (navigation ||
            [<Menu theme="dark" mode="inline" key={"SideNavItems"}>
                <Menu.Item key="1" icon={<HomeOutlined />}>
                    <a href={ROUTE_PATH_HOME}>Home</a>
                </Menu.Item>
                <Menu.Item key="2" icon={<SearchOutlined />}>
                    <a href={ROUTE_PATH_WIKI_MANAGEMENT}>Wiki Management</a>
                </Menu.Item>
                <Menu.Item key="3" icon={<EditOutlined />}>
                    <a href={ROUTE_PATH_MARKDOWN_EDITOR}>MarkDownEditor</a>
                </Menu.Item>
                <Menu.Item key="4" icon={<UploadOutlined />}>
                    <a href={ROUTE_PATH_ASSETS_UPLOAD}>Assets Uploader</a>
                </Menu.Item>
            </Menu>]);
    }

    render() {
        const {breadcrumb, content, notification} = this.props;
        const { collapsed } = this.state;
        return (
            <Layout className="TemplateScreen">
                <Sider trigger={null}
                       width={'auto'}
                       collapsible
                       collapsed={collapsed}>
                    {this.getSideNavigation()}
                </Sider>
                <Layout className="site-layout">
                    <Header className="site-header">
                        <Row>
                            <Col span={1}>
                                {React.createElement(collapsed?MenuUnfoldOutlined:MenuFoldOutlined, {
                                    className: 'trigger',
                                    onClick: ()=>{this.toggle()},
                                })}
                            </Col>
                            <Col span={8}>
                                <Image src={process.env.PUBLIC_URL + HEADER_IMAGE_PATH}
                                       width={100}
                                       height={50}
                                       preview={false}
                                       onClick={()=>this.redirectHome()}/>
                            </Col>
                            <Col span={2} offset={11}>
                                <div>
                                    <ProfileDropDown/>
                                </div>
                            </Col>
                        </Row>
                        {this.getNotification(notification)}
                    </Header>
                    <Layout>
                        {breadcrumb || ''}
                        <Content className="site-content">
                            {content || "Missing Content"}
                        </Content>
                    </Layout>
                </Layout>
            </Layout>
        );
    }
}

TemplateScreen.propTypes = {
    content: PropTypes.object.isRequired,
    notification: PropTypes.object.isRequired,
    breadcrumb: PropTypes.object,
    navigation: PropTypes.object,
}

TemplateScreen.defaultProps = {
    notification: {
        message: '',
        type: 'info',
        visible: false
    }
}
export default TemplateScreen;
