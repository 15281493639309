import { configureStore } from '@reduxjs/toolkit'
import wikiReducer from '../features/wiki-slice'
import subWikiReducer from '../features/subwiki-slice'

const store = configureStore({
  reducer: {
    wikiSlice: wikiReducer,
    subWikiSlice: subWikiReducer
  }
})
export default store;
