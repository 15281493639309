import React from "react";
import {BrowserRouter, Route, Switch} from "react-router-dom";
import {
    ROUTE_PATH_ASSETS_UPLOAD, ROUTE_PATH_HOME,
    ROUTE_PATH_MARKDOWN_EDITOR,
    ROUTE_PATH_WIKI_MANAGEMENT
} from "./constants/routes-constants";
import HomeScreen from "./pages/home";
import WikiManagement from "./pages/wiki-management";
import MarkDownEditor from "./pages/markdown-editor";
import WikiRender from "./pages/wiki-render";
import AssetsUploader from "./pages/assets-uploader";

const Routes = () => (
        <BrowserRouter>
            <div>
                <Switch>
                    <Route path={"/"} exact component={HomeScreen}/>
                    <Route path={ROUTE_PATH_HOME} exact component={HomeScreen} />
                    <Route path={ROUTE_PATH_WIKI_MANAGEMENT} exact component={WikiManagement} />
                    <Route path={ROUTE_PATH_MARKDOWN_EDITOR} exact component={MarkDownEditor} />
                    <Route path={ROUTE_PATH_ASSETS_UPLOAD} exact component={AssetsUploader} />
                    <Route path={"/document/:wiki_id"} exact component={WikiRender} />
                    <Route path={"/document/:wiki_id/*"} exact component={WikiRender} />
                </Switch>
            </div>
        </BrowserRouter>
);

export default Routes;
