import Amplify from "aws-amplify";
import {Auth} from "@aws-amplify/auth";

const configureAmplify = () => {
    const config = {
        Auth: {
            region: 'us-east-1',
            identityPoolRegion: 'us-east-1',
            userPoolId: 'us-east-1_spqxf6rQk',
            userPoolWebClientId: '4k1rr8jlqori91g9qr10ms46vf',
            mandatorySignIn: false,
            authenticationFlowType: 'USER_PASSWORD_AUTH',
        }
    };

    Amplify.configure(config);
    Auth.configure(config);
}

export default configureAmplify;
