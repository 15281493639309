import React, {Component} from "react";
import './App.css';
import '@aws-amplify/ui/dist/style.css';
import {
    SignUp,
    SignIn,
    ConfirmSignUp,
    RequireNewPassword,
    VerifyContact,
    ForgotPassword,
    withAuthenticator
} from 'aws-amplify-react';
import Routes from "./routes";

class App extends Component {
    render() {
        //configureAxios();
        return (
            <div className="App">
                <Routes/>
            </div>
        )
    }
}

export default withAuthenticator(App, true, [
    <SignIn key={"SignIn"}/>,
    <RequireNewPassword key={"RequireNewPassword"}/>,
    <VerifyContact key={"VerifyContact"}/>,
    <ForgotPassword key={"ForgotPassword"}/>,
    <SignUp key={"SignUp"}/>,
    <ConfirmSignUp key={"ConfirmSignUp"}/>,
]);
