import axios from "axios";
import {DATABASE_API, FILE_HANDLER_API, PATH_PARAMETER} from "../constants";
import {Auth} from "@aws-amplify/auth";

const databaseAPIInstance = axios.create({
    baseURL: DATABASE_API,
})

const fileHandlerAPIInstance = axios.create({
    baseURL: FILE_HANDLER_API
});

databaseAPIInstance.interceptors.request.use(async (config) => {
    let token = await Auth.currentSession().then(data =>{
        return data.getIdToken().getJwtToken();
    });
    config.headers = { authorization: token};
    return config;
});

fileHandlerAPIInstance.interceptors.request.use(async (config) => {
    let token = await Auth.currentSession().then(data =>{
        return data.getIdToken().getJwtToken();
    });
    config.headers = { authorization: token};
    return config;
});

export const uploadAssets = async (wikiID, uploadPath, file) => {
    try {
        const fileHandlerURL = `${FILE_HANDLER_API}?` +
            `${PATH_PARAMETER.WIKI_ID}=${wikiID}&${PATH_PARAMETER.PATH}=${uploadPath}/assets/${file.name}`
        let session = await Auth.currentSession();
        const token = session.getIdToken().getJwtToken();

        const request = new XMLHttpRequest();
        request.open("PUT", fileHandlerURL, false);
        request.setRequestHeader("content-type", file.type);
        request.setRequestHeader("Authorization", token);
        request.addEventListener('load', async () => {
            const databaseURL = `/${wikiID}`;
            const response = await databaseAPIInstance.get(databaseURL);
            let attachments = JSON.parse(response.data["attachments"]);
            if (!attachments.includes(uploadPath)) {
                attachments.push(uploadPath);
            }
            const payload = {
                attachments: JSON.stringify(attachments)
            }
            await databaseAPIInstance.post(databaseURL, payload);
        });
        request.send(file['originFileObj']);
    } catch (error) {
        console.log(error);
        throw("Error uploading assets: ", error);
    }
}
