import React, {Component} from 'react';
import PropTypes from "prop-types";
import {connect} from 'react-redux';
import {Table} from 'antd';
import {MODEL_TYPE, NOTIFICATION_TYPE, PATH_PARAMETER, WIKI_METADATA} from "../../../constants";
import {
    getAllWikiMetaData,
} from '../../../services/wiki-service';
import {updateSelectedWiki} from "../../../features/subwiki-slice";

class WikiTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedWiki: {},
            newWikiID: "",
            isCreateWikiModalVisible: false,
            isDeleteWikiModalVisible: false,
            isDeleteWikiButtonDisabled: true,
            confirmDeleteInput: "",
            loading: true,
            columnDef: [
                {
                    title: 'Wiki ID',
                    dataIndex: 'wiki_id',
                    render: (text) => <a href={`/document/${text}`} target="_blank" rel="noreferrer">{text}</a>,
                    sorter: (a, b) => {
                        return a[PATH_PARAMETER.WIKI_ID].localeCompare(b[PATH_PARAMETER.WIKI_ID])
                    },
                    sortDirections: ['ascend'],
                },
                {
                    title: 'Folder',
                    dataIndex: 'folder',
                },
                {
                    title: 'Created On',
                    dataIndex: 'created_on',
                },
                {
                    title: 'Modified On',
                    dataIndex: 'modified_on',
                },
            ]
        }
    }

    handleCreateSubWiki(tableItem) {
        const {dispatch, showModal} = this.props;
        const wikiID = tableItem[WIKI_METADATA.WIKI_ID];
        const documents = JSON.parse(tableItem[WIKI_METADATA.DOCUMENTS]);
        dispatch(updateSelectedWiki({
            wikiID,
            documents
        }));
        showModal(MODEL_TYPE.CREATE_SUB_WIKI);
    }

    handleDeleteSubWiki(tableItem) {
        const {dispatch, showModal} = this.props;
        const wikiID = tableItem[WIKI_METADATA.WIKI_ID];
        const documents = JSON.parse(tableItem[WIKI_METADATA.DOCUMENTS]);
        dispatch(updateSelectedWiki({
            wikiID,
            documents
        }));
        showModal(MODEL_TYPE.DELETE_SUB_WIKI);
    }

    componentDidMount() {
        const {notify, dispatch} = this.props;
        getAllWikiMetaData(dispatch).then(() => {
            this.setState({
                loading: false
            });
        }).catch((error) => {
            notify(JSON.stringify(error), NOTIFICATION_TYPE.ERROR);
        });
    }

    handleWikiTableSelect(rowKey, row) {
        const {onChange} = this.props;
        onChange(row[0]);
        this.setState({
            selectedWiki: row[0],
            isDeleteWikiButtonDisabled: false
        })
    }

    handleConfirmDeleteInput(event) {
        this.setState({
            confirmDeleteInput: event.target.value
        })
    }

    render() {
        const {
            columnDef,
            loading,
        } = this.state;
        const {store} = this.props;
        return (
            <Table key={"WikiTable"}
                   rowSelection={{
                       type: 'radio',
                       onChange: (rowKey, row) => this.handleWikiTableSelect(rowKey, row)
                   }}
                   columns={columnDef}
                   dataSource={store.wikiSlice.wiki}
                   loading={loading}
            />
        )
    }
}

WikiTable.propTypes = {
    dispatch: PropTypes.func.isRequired,
    store: PropTypes.object.isRequired,
    showModal: PropTypes.func.isRequired,
    notify: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired
}

const mapStateToProps = (store) => {
    return {
        store
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        dispatch: dispatch
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(WikiTable);
