import React, {Component} from "react";
import PropTypes from "prop-types";
import {Button, Form, message, Upload} from "antd";
import UploadOutlined from "@ant-design/icons";
import {uploadAssets} from "../../../services/assets-service";

class AssetsUploader extends Component {
    constructor(props) {
        super(props);
        this.state = {
            file: undefined
        }
    }

    handleFileUpload(event) {
        this.setState({
            file: event.file
        })
    }

    async handleSubmit() {
        const loading = message.loading('Uploading in progress..', 0);
        const {wikiID, subPath} = this.props;
        const {file} = this.state;
        uploadAssets(wikiID, subPath, file).then(()=>{
            setTimeout(loading, 1);
            message.success("Successfully Uploaded the Assets");
        }).catch((error)=>{
            setTimeout(loading, 1);
            message.error("Error in Uploading Assets: " + JSON.stringify(error), 0);
        });
    }

    render() {
        return (
            <Form layout={"inline"}
                  name="basic"
                  initialValues={{ remember: true }}
                  onFinish={()=>this.handleSubmit()}>
                <Form.Item
                    name="upload"
                    label="Assets Upload"
                    valuePropName="fileList"
                    getValueFromEvent={(event)=>this.handleFileUpload(event)}
                >
                    <Upload name="logo" action="/upload.do" accept={"image/*"} maxCount={1} multiple={false}>
                        <Button icon={<UploadOutlined/>}>Click to upload</Button>
                    </Upload>
                </Form.Item>
                <Form.Item>
                    <Button type="primary" htmlType="submit">
                        Submit
                    </Button>
                </Form.Item>
            </Form>
    )}
}

AssetsUploader.propTypes = {
    wikiID: PropTypes.string.isRequired,
    subPath: PropTypes.string.isRequired,
    notification: PropTypes.func.isRequired
}
export default AssetsUploader;
