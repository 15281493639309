import React, {Component} from 'react';
import {message, Upload, Form, Input, Button} from "antd";
import UploadOutlined from '@ant-design/icons';
import TemplateScreen from "../../TemplateScreen/TemplateScreen";
import {uploadAssets} from "../../services/assets-service";
import {PATH_PARAMETER} from "../../constants";

const ASSETS_UPLOADING_IN_PROGRESS_MESSAGE = "Uploading in progress...";
const ASSETS_UPLOADING_SUCCESS_MESSAGE = "Successfully Uploaded the Assets";
const ASSETS_UPLOADING_ERROR_MESSAGE = "Error in Uploading Assets ";

class AssetsUploader extends Component {
    constructor(props) {
        super(props);
        this.state = {
            wiki_id: "",
            uploadPath: "",
            file: undefined,
            notification: {}
        }
    }


    async handleSubmit() {
        const loading = message.loading(ASSETS_UPLOADING_IN_PROGRESS_MESSAGE, 0);
        const {wiki_id, uploadPath, file} = this.state;
        uploadAssets(wiki_id, uploadPath, file).then(()=>{
            setTimeout(loading, 1);
            message.success(ASSETS_UPLOADING_SUCCESS_MESSAGE);
        }).catch((error)=>{
            setTimeout(loading, 1);
            message.error(ASSETS_UPLOADING_ERROR_MESSAGE + JSON.stringify(error), 0);
        });
    }

    handleWikiIDChange(event) {
        this.setState({
            wiki_id: event.target.value
        })
    }

    handleUploadPathChange(event) {
        this.setState({
            uploadPath: event.target.value
        })
    }

    handleFileUpload(event) {
        this.setState({
            file: event.file
        })
    }

    render() {
        const {notification} = this.state;
        return (
            <TemplateScreen content={
                <Form
                    labelCol={{span: 2}}
                    wrapperCol={{ span: 8 }}
                    name="basic"
                    initialValues={{ remember: true }}
                    onFinish={()=>this.handleSubmit()}
                >
                    <Form.Item
                        label="Wiki ID"
                        name={PATH_PARAMETER.WIKI_ID}
                        rules={[{ required: true, message: 'Please input your wiki id' }]}
                    >
                        <Input onChange={(event)=>this.handleWikiIDChange(event)} />
                    </Form.Item>

                    <Form.Item
                        label="Upload Path"
                        name={PATH_PARAMETER.PATH}
                        rules={[{ required: true, message: 'Please input your upload path' }]}
                    >
                        <Input onChange={(event)=>this.handleUploadPathChange(event)} />
                    </Form.Item>

                    <Form.Item
                        name="upload"
                        label="Upload"
                        valuePropName="fileList"
                        getValueFromEvent={(event)=>this.handleFileUpload(event)}
                    >
                        <Upload name="logo" action="/upload.do" accept={"image/*"} maxCount={1} multiple={false}>
                            <Button icon={<UploadOutlined/>}>Click to upload</Button>
                        </Upload>
                    </Form.Item>

                    <Form.Item wrapperCol={{ offset: 2, span: 16 }}>
                        <Button type="primary" htmlType="submit">
                            Submit
                        </Button>
                    </Form.Item>
                </Form>} notification={{notification}}/>
        );
    }
}

export default AssetsUploader;
