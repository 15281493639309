import React, {Component}from 'react';
import TemplateScreen from "../../TemplateScreen/TemplateScreen";
import {Col, Image, Row, Typography} from 'antd';

const { Title, Paragraph, Text, Link } = Typography;

class HomeScreen extends Component {
    render() {
        return (
            <TemplateScreen
                content={
                    <>
                        <Row style={{marginBottom: "20px"}}>
                            <Col span={8} offset={0}>
                                <Image height={100} width={100} src={'/api/FileHandlers?wiki_id=Home&path=IDPhoto.jpg'}></Image>
                            </Col>
                            <Col span={8} offset={8}>
                                <div style={{textAlign: 'right'}}>
                                    <b>Binwei Liu (Lamy)</b> <br/>
                                    <b>Email: </b> binweili@usc.edu <br/>
                                    <b>Phone: </b> (949)394-4530 <br/>
                                    <b>LinkedIn: </b>
                                    <Link href="www.linkedin.com/in/binwei-liu">www.linkedin.com/in/binwei-liu</Link>
                                </div>
                            </Col>
                        </Row>
                        <Row style={{marginBottom: "20px"}}>
                            <Paragraph>
                                2+ years of industry experience in designing and developing software packages, automated release flow, and cloud services.
                                Proficient in designing microservices using cloud-native AWS services.
                                Interested in broad areas such as cloud-computing, computer vision, and autonomous driving
                            </Paragraph>
                        </Row>
                        <Row style={{background: "#82a2ec"}}>
                            <Title level={4} style={{marginLeft:"10px"}}>Work Experience</Title>
                        </Row>
                        <Row style={{marginBottom: "20px"}}>
                            <Row style={{width:"100%"}}>
                                <Col span={8}>
                                    <Text strong={true}>Amazon Taskless - Software Development Engineer</Text>
                                </Col>
                                <Col span={8} offset={8}>
                                    <div style={{textAlign: 'right'}}>
                                        <Text strong={true}>Nov 2019 – May 2021</Text>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Text style={{fontStyle: "italic"}}>
                                    Design, implement, and deploy FinTech related software components and features, which provide services to both internal and external customers.
                                </Text>
                            </Row>
                            <Row>
                                <Paragraph>
                                    <ul>
                                        <li>
                                            Designed and implemented authentication and authorization service with OAuth 2.0 and OpenID Connection, which is used to limit customers’ access to features based on AWS IAM roles
                                        </li>
                                        <li>
                                            Created a self-servicing portal website for the authentication service, so that clients could manage the access to their data independent on my team
                                        </li>
                                        <li>
                                            Developed and optimized a tax report downloading feature, which asynchronously called downstream service’s API with rate limiter and aggregate for customers to download
                                        </li>
                                        <li>
                                            Designed and implemented a full-stack native AWS (NAWS) wiki application, where technical writers could upload user guide wiki and external customers could login to view the wiki
                                        </li>
                                        <li>
                                            Introduced AWS Cloud Development Kit to team for automating deployments of infrastructures
                                        </li>
                                        <li>
                                            Improved code review process by introducing code linting into teams’ JavaScript repositories
                                        </li>
                                    </ul>
                                </Paragraph>
                            </Row>
                        </Row>
                        <Row style={{marginBottom: "20px"}}>
                            <Row style={{width:"100%"}}>
                                <Col span={12}>
                                    <Text strong={true}>
                                        Amazon Alexa Monetization - Software Development Engineer
                                    </Text>
                                </Col>
                                <Col span={12}>
                                    <div style={{textAlign: 'right'}}>
                                        <Text strong={true}>
                                            May 2021 - Now
                                        </Text>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Text style={{fontStyle: "italic"}}>
                                    Design, implement, and deploy Alexa Skill publishing and purchasing platform, and expand Alexa Skill to multiple countries outside US.
                                </Text>
                            </Row>
                            <Row>
                                <Paragraph>
                                    <ul>
                                        <li>
                                            Launched the Alexa celebrity voice skill (Amitabh Bachchan) to Indian Amazon retail website including onboarding Indian’s payment services, clarifying FinTech related requirements, and building voice UI purchasing workflows.
                                        </li>
                                        <li>
                                            Worked as away team to build the Amitabh Bachchan Skill’s detail page on Amazon.in, including the purchasing box, skill descriptions, and advertising images/videos.
                                        </li>
                                        <li>
                                            Designed and implemented a developer reward calculation service with AWS Elastic Map Reduce. The service runs Spark jobs to extract 3rd party skill usage data from Redshift data warehouse, transforms the skill usage data to developer rewards amount, and loads the developer rewards to DynamoDB table which is used to send out monthly payout events to developers
                                        </li>
                                        <li>
                                            Proposed on-call consolidation plan to leadership for increasing the operational excellence of my team, so that customer impacting tickets can be mitigated faster
                                        </li>
                                        <li>
                                            Hosted cross team learning sessions includes introducing team services, AWS technologies, and basic natural language processing (NLP) knowledges with CNN
                                        </li>
                                    </ul>
                                </Paragraph>
                            </Row>
                        </Row>
                        <Row style={{marginBottom: "20px",background: "#82a2ec"}}>
                            <Title level={4} style={{marginLeft:"10px"}}>Technical Skills & Certification</Title>
                        </Row>
                        <Row>
                            <ul>
                                <li>
                                    <Row>
                                        <Col span={8}>
                                            AWS Certified Solutions Architect – Associate
                                        </Col>
                                        <Col span={8} offset={8}>
                                            <div style={{textAlign: "right"}}>
                                                Credential ID: 5ZHJHEQ1Y2V4Q4K0
                                            </div>
                                        </Col>
                                    </Row>
                                </li>
                                <li>
                                    <Row>
                                        <Col span={8}>
                                            AWS Cloud Practitioner
                                        </Col>
                                        <Col span={8} offset={8}>
                                            <div style={{textAlign: "right"}}>
                                                Credential ID: 92NXJGXLEE4EQ1WP
                                            </div>
                                        </Col>
                                    </Row>

                                </li>
                                <li>
                                    Front-End Skills: React, Angular
                                </li>
                                <li>
                                    Back-End Skills: Architecture Design with AWS Services, SQL & Non-SQL Databases, REST API Design, AWS Redshift data warehouse, AWS Sage Maker
                                </li>
                                <li>
                                    Programming Languages: Java, JavaScript, TypeScript, Python, SQL
                                </li>
                            </ul>
                        </Row>
                        <Row style={{marginBottom: "20px",background: "#82a2ec"}}>
                            <Title level={4} style={{marginLeft:"10px"}}>Education</Title>
                        </Row>
                        <Row>
                            <ul>
                                <li>
                                    M.S, University of Southern California - Mechanical Engineering – Robotics
                                </li>
                                <li>
                                    B.S, University of California, Irvine - Mechanical Engineering
                                </li>
                            </ul>
                        </Row>
                    </>
            }/>
        );
    }
}

export default HomeScreen;
