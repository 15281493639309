import React, {Component} from 'react';
import { Menu, Dropdown, Avatar} from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { Auth } from "aws-amplify";

class ProfileDropDown extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: {
                username: "",
                attributes: {
                    email: ""
                }
            }
        }
    }

    componentDidMount() {
        Auth.currentUserInfo().then(user=>{
            this.setState({
                user
            })
        });
    }

    renderMenu() {
        const { user } = this.state;
        return (
            <Menu>
                <Menu.Item disabled>
                    Username: {user.username || 'Please Log In'}
                </Menu.Item>
                <Menu.Item disabled>
                    Email: {user.attributes.email || 'Please Log In'}
                </Menu.Item>
                <Menu.Item>
                    <div target="_blank" onClick={this.amplifySignOut}>Sign Out</div>
                </Menu.Item>
            </Menu>
        )
    }

    amplifySignOut() {
        Auth.signOut().then(()=>{
            window.location.href = "/";
        })
    }

    render() {
        const { user } = this.state;
        return (
            <Dropdown overlay={this.renderMenu(user.username)}>
                <Avatar shape="square" icon={<UserOutlined />}></Avatar>
            </Dropdown>
        )
    }
}

export default ProfileDropDown;
